import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Head from "../components/head"
import Header from "../components/header"
import Footer from "../components/footer"
import { LoginHeader } from "../components/nav"
import { SocialHeader } from "../components/social"

import Nav from "../components/nav"
import styles from "../styles/global.css"
import security from "../styles/security.css"
import media from "../styles/media.css"

// export default function Home() {
//   return <div>Hello world!</div>
// }
export function Banner_img() {
  return <StaticImage src="../../static/images/jobs-hd.jpg" alt="banner" />
}
export default () => (
  <>
    <Header titleText="Security - Happy Mango" />
    <div className="main">
      <div className="PC_head">
        <LoginHeader />
        <SocialHeader />
        <div className="logo_div">
          <a rel="" href="/" className="logo" title="Happy Mango">
            <img
              className="light"
              src="/images/hm-logo-v2.png"
              alt="Happy Mango"
            />
          </a>
        </div>
        <ul className="nav_ul">
          <li className="nav_li">
            <a rel="" href="/services/">
              Our Services
            </a>
            <ul className="nav_ul_son">
              <li className="nav_li_son">
                <a rel="" href="/services/#Digitalize">
                  Front to Back Digitalization
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Customize">
                  Customized Underwriting
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Expand">
                  Digital Marketing Tools
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Improve">
                  Financial Health Management
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Track">
                  Impact Analysis and Reporting
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/clients/">
              Our Clients
            </a>
            <ul className="nav_ul_son nav_ul_son_se">
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Banks
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Credit Unions
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  CDFIs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/why/">
              Why Happy Mango?
            </a>
          </li>
        </ul>
        <div className="clear_both"></div>
      </div>

      <Head />

      <div className="banner_contact">
        <img className="contact_img" src="/images/gate-crop.jpg" alt="banner" />
        <h1 className="bannerinfo">SECURITY</h1>
      </div>

      <div className="security">
        <div className="view-content">
          <div className="item">
            <div>
              <div className="title">
                Why does Happy Mango need my bank login information (username
                and password)?
              </div>
            </div>
            <div>
              <div>
                <div className="excerpt">
                  <p>
                    For those users who opt-in to use our personal finance
                    management tools or choose to use online banking to connect
                    their payment accounts, we will ask for your online banking
                    username and password to establish a secure connection with
                    your bank or credit card company. This gives us access to
                    your bank and credit card statements. Information on those
                    statements enables Happy Mango to calculate your score
                    accurately and present your financial story completely. We
                    do not see or store your online financial account login
                    credentials and cannot move money out of your account
                    without your personal authorization.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div>
              <div className="title">
                Do you store my bank login information on your servers?
              </div>
            </div>
            <div>
              <div>
                <p>
                  No. &nbsp;Happy Mango does not see or store your online login
                  credentials. &nbsp;Once a connection with your bank is
                  successfully established, your online login is no longer
                  necessary for us to retrieve your bank statement information
                  from the bank.
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div>
              <div className="title">
                How can I protect my Happy Mango account?
              </div>
            </div>
            <div>
              <div>
                <p>
                  Don’t share your Happy Mango password or your other passwords
                  with anyone.
                </p>
                <p>
                  Make sure that your password is complex, including both
                  numbers and capital letters.
                </p>
                <p>
                  Be certain that you have virus protection and a firewall on
                  any computer you use to access Happy Mango.
                </p>
                <p>
                  Don’t install programs from people or companies you don’t
                  know.
                </p>
                <p>
                  To request the latest information security policy, please send
                  an email to &nbsp;
                  <a
                    className="email"
                    rel=""
                    href="mailto:support@happymangocredit.com"
                  >
                    support@happymangocredit.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div>
              <div className="title">
                If someone does manage to steal my Happy Mango log in
                information, can they access my bank accounts and credit cards
                to make any transactions?
              </div>
            </div>
            <div>
              <div>
                <p>
                  No. Happy Mango provides a strictly “read only” view of your
                  banking information and credit card statements. Your online
                  banking user names and passwords are never displayed after you
                  enter them during your first session.
                </p>
              </div>
            </div>
          </div>
          <div className="item">
            <div>
              <div className="title">
                How can I close my Happy Mango account?
              </div>
            </div>
            <div>
              <div>
                <p>Login to your Happy Mango account.</p>
                <ul>
                  <li>Click on Contact</li>
                  <li>
                    Use the form to send us a request to close your account
                  </li>
                </ul>
                <p>
                  Your Happy Mango account data will be removed from our
                  production server within 5 business days. Your data may remain
                  on a backup server. Happy Mango keeps these backups to ensure
                  our continued ability to provide our service to our users in
                  the event of malfunction or damage to our primary production
                  servers.
                </p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
    <script src="/js/media_page.js"></script>
  </>
)
